// @ts-nocheck
import React from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";

function Iframe({ setShowIframe, setIframeUrl, iframeUrl }) {
  const closeIframe = () => {
    setShowIframe(false);
    setIframeUrl("");
    window.location.reload();
  };

  return (
    <div
      className="w-100 h-100 position-absolute top-0 start-0"
      style={{
        zIndex: "99999999",
        backgroundColor: "#fff",
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
      }}
    >
      <button
        onClick={closeIframe}
        className="btn bg-transparent position-absolute translate-middle"
        style={{
          width: "30px",
          height: "30px",
          zIndex: "100",
          top: ".5rem",
        }}
      >
        <XCircleIcon width={40} className="text-danger" />
      </button>

      <iframe
        src={iframeUrl}
        frameBorder="0"
        className="position-fixed w-100 h-100"
        style={{
          zIndex: "0",
        }}
      ></iframe>
    </div>
  );
}

export default Iframe;
