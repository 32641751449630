import React from "react";
// lib
import { HashLoader } from "react-spinners";

const Loading = ({ loading = false, full = false }) => {
  return (
    <div
      className={`my-auto d-flex ${
        full && "vh-100"
      } justify-content-center align-items-center`}
    >
      <HashLoader
        color="#E2A101"
        loading={loading}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;
