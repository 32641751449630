import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Empty = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="w-100 opac show_bg">
      <button
        onClick={() => navigate(`/${location.pathname.split("/")[1]}`)}
        className="btn_div_no_img btn btn-danger shadow-lg btn-lg text-uppercase fw-bolder font-monospace rounded-2"
      >
        Return Back
      </button>
    </div>
  );
};
