import React from "react";
import { useState } from "react";
import icon from "../../asset/image/livechat.png";

const LiveChat = () => {
  const [open, setIsOpen] = useState(false);
  return (
    <main>
      <div
        onClick={() => window.open("https://t.me/sotmel_O")}
        className="livechat football"
      >
        <img className="p-2" style={{ width: "5rem" }} src={icon} alt="livec" />
      </div>
      <div className={`${open ? "d-block" : "d-none"} lciframe`}>
        <iframe
          src="https://sotmel.com/livechat.html"
          title="Live Chat Widget"
          className="livechatiframe"
        />
      </div>
    </main>
  );
};

export default LiveChat;
