// @ts-nocheck
import React from "react";
//image
import logo from "../../asset/image/favicon.png";
import noimage from "../../asset/image/no-image.png";

import {
  GiftIcon,
  BellIcon,
  BuildingStorefrontIcon,
  CreditCardIcon,
  BanknotesIcon,
  ArrowLeftOnRectangleIcon,
  ListBulletIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoginContext } from "../../store/context/LoginProvider";
import { USER_LOGOUT } from "../../store/actions/actionTypes";
import { BaseURL } from "../../api/apiUrl";
import { useEffect } from "react";
import { getProfileApi } from "../../api/profileApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Sidebar = ({ loading }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState("");

  let profile_image = image?.startsWith("https")
    ? image
    : `${BaseURL}/${image}`;
  const [
    {
      userData,
      gameManagement: { category },
    },
    dispatch,
  ] = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    userData &&
      getProfileApi({ token: userData?.token }).then((res) => {
        setImage(res.image);
      });
  }, [userData]);

  const handleLogout = () => {
    if (!window.confirm("Are you sure want to logout ?")) return;
    dispatch({ type: USER_LOGOUT });
    navigate("/");
  };

  return (
    <div
      className="vh-100 position-fixed top-0 d-none d-md-block col-md-3 col-lg-2 bg-black bg-opacity-50 shadow overflow-auto pb-2"
      style={{ zIndex: 110 }}
    >
      <div
        onClick={() => navigate("/")}
        style={{ backgroundColor: "#6A0002" }}
        className="rounded-bottom text-center cursor-pointer"
      >
        <LazyLoadImage effect="blur" className="w-25 img-fluid" src={logo} />
      </div>

      {/** Profile Div */}
      <div
        className="text-center mx-auto rounded-2 "
        style={{
          width: "90%",
          backgroundColor: "#212121",
          marginTop: "4rem",
        }}
      >
        <div className="d-block">
          <LazyLoadImage
            effect="blur"
            className=" translate-middle-y rounded-circle"
            style={{ width: "5rem", height: "5rem", objectFit: "cover" }}
            src={userData ? profile_image : noimage}
          />
          <div>
            <button
              onClick={() => navigate("/profile")}
              className="btn btn-warning translate-middle-y rounded-pill px-5"
            >
              <b>{userData?.token ? `${t("profile")}` : `${t("login")}`}</b>
            </button>
          </div>
        </div>

        <div className="d-flex gap-2 mx-2 justify-content-center pb-2">
          <div className="text-warning opacity-25 bg-black w-50 rounded-2">
            <BellIcon width={40} />
            <small className=" d-block">{t("notice")}</small>
          </div>
          <div className="text-warning opacity-25 bg-black w-50 rounded-2">
            <GiftIcon width={40} />
            <small className=" d-block">{t("category.promotion")}</small>
          </div>
        </div>
      </div>

      {/** History */}
      {userData && (
        <div
          onClick={() => navigate("/betslip")}
          style={{ width: "90%" }}
          className="mx-auto bg-success bg-opacity-25 d-flex align-items-center gap-3 rounded-3 mt-3 py-2 cursor-pointer"
        >
          <BuildingStorefrontIcon className="text-success ms-4" width={25} />
          <div className=" text-md text-white text-opacity-50">
            {t("history")}
          </div>
        </div>
      )}

      {/** WinnerList & Win-Num */}
      {userData && (
        <div
          style={{ width: "90%", backgroundColor: "#212121" }}
          className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
        >
          <div
            onClick={() => navigate("/winner-list")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <UserGroupIcon className="text-warning ms-4" width={25} />
            <div className=" text-md text-white text-opacity-50">
              {t("winnerListHistory.title")}
            </div>
          </div>

          <div
            onClick={() => navigate("/win-number")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <ListBulletIcon className="text-warning ms-4" width={25} />
            <div className=" text-md text-white text-opacity-50">
              {t("winNoHistory")}
            </div>
          </div>

          {/** <div
            onClick={() => navigate("/referral")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <img
              src="https://static.thenounproject.com/png/2215862-200.png"
              width="25"
              className="bg-warning bg-opacity-75 rounded-circle p-1 ms-4"
            />
            <div className=" text-md text-white text-opacity-50">
              {t("friend")}
            </div>
          </div> */}
        </div>
      )}

      {/** Transfer */}
      {userData && (
        <div
          style={{ width: "90%", backgroundColor: "#212121" }}
          className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
        >
          <div
            onClick={() => navigate("/cashin")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <CreditCardIcon className="text-warning ms-4" width={25} />
            <div className=" text-md text-white text-opacity-50">
              {t("cashIn")}
            </div>
          </div>

          <div
            onClick={() => navigate("/cashout")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <BanknotesIcon className="text-warning ms-4" width={25} />
            <div className=" text-md text-white text-opacity-50">
              {t("cashOut")}
            </div>
          </div>
        </div>
      )}

      {/** Category */}
      <div
        style={{ width: "90%", backgroundColor: "#212121" }}
        className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
      >
        <div
          onClick={() => navigate("/")}
          className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
        >
          <LazyLoadImage
            effect="blur"
            className="ms-4"
            style={{ width: "1.5rem" }}
            src="https://cdn-icons-png.flaticon.com/512/1299/1299961.png"
          />
          <div className=" text-md text-white pt-1 font-monospace fw-bolder text-opacity-50">
            {t("home")}
          </div>
        </div>

        {!loading ? (
          category?.map((cat, idx) => (
            <div
              key={idx}
              onClick={() => navigate(`${cat.name}`)}
              className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
            >
              <LazyLoadImage
                effect="blur"
                className="ms-4"
                style={{ width: "1.5rem" }}
                src={`${BaseURL}/${cat.image}`}
              />
              <div className="text-md text-white font-monospace pt-1 text-opacity-50 fw-bolder">
                {cat.name.toUpperCase()}
              </div>
            </div>
          ))
        ) : (
          <div className="w-100 h-auto">
            {Array.from({ length: 5 }).map((_, idx) => (
              <div key={idx} className="d-flex align-items-center gap-2">
                <div
                  className="pulse activebg-opacity-50 my-2 rounded-circle"
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginLeft: "1.4rem",
                  }}
                ></div>
                <div
                  key={idx}
                  className="pulse text-center text-opacity-25 bg-opacity-50 my-2 py-1 rounded-5"
                  style={{ width: "60%", height: "1.3rem" }}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/** Logout */}
      {userData && (
        <div
          onClick={handleLogout}
          style={{ width: "90%" }}
          className="mx-auto bg-danger bg-opacity-25 d-flex align-items-center gap-3 rounded-3 mt-3 py-2 cursor-pointer"
        >
          <ArrowLeftOnRectangleIcon className="text-danger ms-4" width={25} />
          <div className=" text-md text-white text-opacity-50">
            {t("logout")}
          </div>
        </div>
      )}
    </div>
  );
};
