import React from "react";
// lib
import { useNavigate } from "react-router-dom";

function NotFound() {
  let navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div
        className="row d-flex align-items-center"
        style={{ height: "500px" }}
      >
        <div className="p-5 text-center " style={{ color: "#D4AF37" }}>
          <h1 className="fw-bolder pb-3" style={{ fontSize: "50px" }}>
            404 - PAGE NOT FOUND
          </h1>
          <h6>Sorry😞! The page you requested was not found😞.</h6>
          <button onClick={() => navigate("/")} className="btn btn-warning mt-4">Go To Home</button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
